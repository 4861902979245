import Vue from 'vue';

// Import app component
// import App from '@components/app';
import Application from '@components/application/Application';

/**
 * Factory for creating application
 */
export default {
  /**
   * Create new Application instance
   */
  create: ({
    // config,
    router, store, vuetify,
  }) => (
    // Return new instance of application
    new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      async mounted() {
        if (this.checkLogin()) {
          // Initialize store modules and wait for results
          await this.$store.dispatch('initLogin', { apiClient: this.$http, config: CONFIG });
        } else {
          // Initialize store modules and wait for results
          await this.$store.dispatch('init', { apiClient: this.$http, config: CONFIG });
        }
      },
      methods: {
        checkLogin() {
          const token1 = localStorage.getItem('fm-oauth');

          return (token1 === null);
        },
      },
      render: (h) => h(Application),
    })
  ),
};
