import { create } from 'apisauce';

export default {
  async install(Vue, { url }) {
    const progress = Vue.prototype.$Progress;
    const api = create({
      baseURL: url.api || '',
    });

    api.setHeader('Access-Control-Allow-Origin', '*');

    api.axiosInstance.defaults.validateStatus = () => true;

    Vue.prototype.$bkf.api = api;
    Vue.prototype.$api = Vue.prototype.$bkf.api;

    if (progress) {
      let connections = 0;

      api.addRequestTransform(() => {
        if (connections) {
          progress.increase(10);
        } else {
          progress.start();
        }
        connections += 1;
      });

      api.addResponseTransform(() => {
        connections = Math.max(connections - 1, 0);

        if (!connections) {
          progress.finish();
        }
      });
    }
  },
};
