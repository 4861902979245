<template>
  <form
    id="login_form"
    class="login__form reset__form"
    novalidate="novalidate"
    @submit.stop.prevent="reset"
  >
    <md-toolbar
      :md-theme="title.theme"
    >
      <span class="md-title">{{ title.message|trans }}</span>
    </md-toolbar>
    <div class="login__form__inner">
      <md-input-container>
        <label>
          {{ 'reset.username.placeholder'|trans }}
        </label>
        <md-input
          id="email"
          v-model="email"
          name="email"
          type="email"
        />
        <span
          class="md-error"
        >
          {{ 'reset.username.invalid'|trans }}
        </span>
      </md-input-container>
      <div class="login__form__actions">
        <router-link :to="{ name: 'login' }">
          <md-button
            class="md-primary"
          >
            {{ 'reset.back'|trans }}
          </md-button>
        </router-link>
        <md-button
          class="md-primary md-raised"
          :disabled="resetDisabled"
          type="submit"
        >
          {{ 'reset.reset'|trans }}!
        </md-button>
      </div>
    </div>
  </form>
</template>

<script>

export default {
  props: {
    language: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      email: '',
      title: {
        theme: 'default',
        message: 'reset.title.default',
      },
      resetDisabled: false,
    };
  },
  computed: {
    validEmail() {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
    },
    brand() {
      return CONFIG.brand.name;
    },
  },
  watch: {
    language() {
      this.$forceUpdate();
    },
  },
  methods: {
    async reset() {
      const { title, validEmail } = this;
      title.message = validEmail ? 'reset.title.default' : 'reset.title.not-email';
      title.theme = validEmail ? 'default' : 'alert';

      if (validEmail === false) {
        return false;
      }

      const { status } = await this.$api.get('/api/users/password/reset', { email: this.email, brand: this.brand, lang: this.language });

      if (status === 204) {
        this.resetDisabled = true;
        title.message = 'reset.title.link-send';
        title.theme = 'success';
        return true;
      }

      this.resetDisabled = true;
      title.message = 'reset.title.send-error';
      title.theme = 'alert';
      return false;
    },
  },
};
</script>
