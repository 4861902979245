import { get as getApi } from '@services/api';
import flatten from '@utils/flatten';
import router, { pages } from '../../router';

const mutations = {
  ROUTES_Add: 'ROUTES_Add',
};

/**
 * Module responsible for managing application routes.
 */
export default {
  namespaced: true,
  state: {
    routes: [],
  },
  getters: {
    routes: (s) => s.routes,
  },
  mutations: {
    [mutations.ROUTES_Add](state, routes) {
      state.routes.push(...flatten(routes, 'children'));
      router.addRoutes(routes);
    },
  },
  actions: {
    async loadRoutes({ commit }) {
      const map = new Map();
      const routes = [];
      const api = getApi();
      const { data, status } = await api.get('/routes');

      if (status !== 200) {
        return;
      }

      data.routes.push(
        {
          name: 'login',
          path: '/login',
          title: 'Login',
          component: 'LogoutForm',
          menu: false,
          login: true,
        },
        {
          name: 'dashboard',
          path: '/dashboard',
          title: 'menu.dashboard',
          component: 'Dashboard',
          icon: 'home',
          menu: true,
        },
        // {
        //   name: 'diagnostics',
        //   path: '/diagnostics',
        //   title: 'menu.monitor',
        //   component: 'Diagnostics',
        //   icon: 'timeline',
        //   menu: true,
        // },
        // {
        //   name: 'location',
        //   path: '/location',
        //   title: 'menu.devices-localization',
        //   component: 'DevicesLocation',
        //   icon: 'map',
        //   menu: true,
        // },
        // {
        //   name: 'alerts',
        //   path: '/alerts',
        //   title: 'menu.alerts',
        //   component: 'Alerts',
        //   redirect: 'alert-rules',
        //   icon: 'notifications',
        //   menu: true,
        // },
        // {
        //   name: 'alerts-list',
        //   parent: 'alerts',
        //   path: 'alerts',
        //   title: 'menu.alert-list',
        //   component: 'Alerts',
        //   menu: true,
        // },
        // {
        //   name: 'alert',
        //   parent: 'alerts-list',
        //   path: ':id',
        //   title: 'menu.alert',
        //   component: 'Alert',
        // },
        // {
        //   name: 'news-items',
        //   path: '/news',
        //   title: 'menu.news',
        //   component: 'NewsItems',
        // },
        // {
        //   name: 'news-item',
        //   parent: 'news-items',
        //   path: ':id',
        //   title: 'menu.news-item',
        //   component: 'NewsItem',
        // },
        // {
        //   name: 'reports',
        //   path: '/reports',
        //   title: 'menu.reports',
        //   component: 'Reports',
        //   icon: 'description',
        //   menu: true,
        // },
        // {
        //   name: 'alerts-report',
        //   parent: 'reports',
        //   path: 'alerts-report',
        //   title: 'report.predefined.alerts.title',
        //   component: 'AlertsReport',
        //   menu: false,
        // },
        // {
        //   name: 'efficiency-report',
        //   parent: 'reports',
        //   path: 'efficiency-report',
        //   title: 'report.predefined.efficiency.title',
        //   component: 'EfficiencyReport',
        //   menu: false,
        // },
        // {
        //   name: 'device-operators-report',
        //   parent: 'reports',
        //   path: 'device-operators-report',
        //   title: 'report.predefined.device-operators.title',
        //   component: 'DeviceOperatorsReport',
        //   menu: false,
        // },
        // {
        //   name: 'shifts-report',
        //   parent: 'reports',
        //   path: 'shifts-report',
        //   title: 'report.predefined.shifts.title',
        //   component: 'ShiftsReport',
        //   menu: false,
        // },
        // {
        //   name: 'abstract-report',
        //   parent: 'reports',
        //   path: 'abstract-report',
        //   title: 'report.predefined.device-operators.title',
        //   component: 'AbstractReport',
        //   menu: false,
        // },
        // {
        //   name: 'device-operators-detailed-report',
        //   parent: 'reports',
        //   path: 'device-operators-detailed-report',
        //   title: 'report.predefined.device-operators-detailed.title',
        //   component: 'DeviceOperatorsDetailedReport',
        //   menu: false,
        // },
        // {
        //   name: 'manage',
        //   path: '/manage',
        //   title: 'menu.manage',
        //   component: 'Manage',
        //   redirect: 'alert-rules',
        //   icon: 'settings',
        //   menu: true,
        // },
        // {
        //   name: 'devices',
        //   parent: 'manage',
        //   path: 'devices',
        //   component: 'Devices',
        //   title: 'menu.devices',
        //   menu: true,
        // },
        // {
        //   name: 'device',
        //   parent: 'devices',
        //   path: ':id',
        //   title: 'menu.device-details',
        //   component: 'Device',
        // },
        // {
        //   name: 'device-groups',
        //   parent: 'manage',
        //   path: 'device-groups',
        //   title: 'menu.device-groups',
        //   component: 'DeviceGroups',
        //   menu: true,
        // },
        // {
        //   name: 'device-group',
        //   parent: 'device-groups',
        //   path: ':id',
        //   title: 'menu.device-group-details',
        //   component: 'DeviceGroup',
        // },
        // {
        //   name: 'device-operators',
        //   parent: 'manage',
        //   path: 'device-operators',
        //   title: 'menu.device-operators',
        //   component: 'DeviceOperators',
        //   menu: true,
        // },
        // {
        //   name: 'device-operator',
        //   parent: 'device-operators',
        //   path: ':id',
        //   title: 'menu.device-operator-details',
        //   component: 'DeviceOperator',
        // },
        // {
        //   name: 'device-operator-groups',
        //   parent: 'manage',
        //   path: 'device-operator-groups',
        //   title: 'menu.device-operator-groups',
        //   component: 'DeviceOperatorGroups',
        //   menu: true,
        // },
        // {
        //   name: 'device-operator-group',
        //   parent: 'device-operator-groups',
        //   path: ':id',
        //   title: 'menu.device-operator-group-details',
        //   component: 'DeviceOperatorGroup',
        // },
        // {
        //   name: 'sensors',
        //   parent: 'manage',
        //   path: 'sensors',
        //   title: 'menu.sensors',
        //   component: 'Sensors',
        //   menu: true,
        // },
        // {
        //   name: 'sensor',
        //   parent: 'sensors',
        //   path: ':id',
        //   title: 'menu.sensor-details',
        //   component: 'Sensor',
        // },
        // {
        //   name: 'users',
        //   path: '/users',
        //   title: 'menu.users',
        //   component: 'Users',
        //   menu: true,
        // },
        // {
        //   name: 'alert-rules',
        //   parent: 'alerts',
        //   path: 'alert-rules',
        //   title: 'menu.alert-rules',
        //   component: 'AlertRules',
        //   menu: true,
        // },
        // {
        //   name: 'alert-rule',
        //   parent: 'alert-rules',
        //   path: ':id',
        //   title: 'menu.alert-rule',
        //   component: 'AlertRule',
        // },
        // {
        //   name: 'production-view',
        //   path: '/production-view',
        //   title: 'menu.production-view',
        //   component: 'ProductionView',
        //   icon: 'visibility',
        //   menu: true,
        // },
        // {
        //   name: 'help',
        //   path: '/help',
        //   title: 'menu.help',
        //   component: 'Help',
        //   icon: 'help',
        //   menu: true,
        // },
        // {
        //   name: 'device-efficiency-report',
        //   parent: 'reports',
        //   path: '/reports/device-efficiency-report',
        //   title: 'report.type.device-efficiency-report',
        //   component: 'DeviceEfficiencyReport',
        //   icon: 'help',
        //   menu: false,
        // },
        // {
        //   name: 'administration-devices',
        //   path: '/administration-devices',
        //   title: 'menu.administration-device',
        //   component: 'AdministrationDevices',
        //   menu: true,
        // },
        // {
        //   name: 'administration-sensors',
        //   path: '/administration-sensors',
        //   title: 'menu.administration-sensors',
        //   component: 'AdministrationSensors',
        //   menu: true,
        // },
        // {
        //   name: 'administration-users',
        //   path: '/administration-users',
        //   title: 'menu.administration-users',
        //   component: 'AdministrationUsers',
        //   menu: true,
        // },
        // {
        //   name: 'administration-user-groups',
        //   path: '/administration-user-groups',
        //   title: 'menu.administration-user-groups',
        //   component: 'AdministrationUserGroups',
        //   menu: true,
        // },
        // {
        //   name: 'administration-devices-types',
        //   path: '/administration-devices-types',
        //   title: 'menu.administration-devices-types',
        //   component: 'AdministrationDevicesTypes',
        //   menu: true,
        // },
      );

      data.routes.forEach((record) => {
        const route = {
          name: record.name,
          component: pages[record.component],
          path: record.path,
          // Automagically pass route params as props to component
          props: ({ params }) => {
            const props = {};
            // If id is defined, cast it to number
            if (params.id) {
              props.id = Number(params.id);
            }

            return props;
          },
          children: [],
          meta: {
            login: record.login === true,
            title: record.title,
            redirect: !!record.redirect,
            root: record.parent === undefined,
            menu: record.menu === true,
            icon: record.icon,
          },
        };

        if (record.redirect) {
          route.redirect = { name: record.redirect };
        }

        if (record.parent) {
          map.get(record.parent).children.push(route);
        }

        map.set(route.name, route);
      });

      routes.push(...[...map.values()].filter((r) => r.meta.root));
      commit(mutations.ROUTES_Add, routes);
    },
    async loadRoutesLogin({ commit }) {
      const map = new Map();
      const routes = [];

      const loginRoutes = [
        {
          name: 'default',
          path: '/',
          redirect: 'login',
        },
        {
          name: 'login',
          path: '/login',
          component: 'LoginForm',
          title: 'Login',
          menu: false,
          login: true,
        },
        {
          name: 'reset',
          path: '/reset',
          title: 'reset',
          component: 'ResetForm',
          login: true,
        },
        {
          name: 'resetting',
          path: '/resetting',
          component: 'ResettingForm',
          login: true,
        },
        {
          name: 'terms',
          path: '/terms',
          component: 'Terms',
          login: true,
        },
        {
          name: 'terms_en',
          path: '/terms_en',
          component: 'TermsEn',
          login: true,
        },
        {
          name: 'dashboard',
          path: '/dashboard',
          title: 'menu.dashboard',
          component: 'Dashboard',
          icon: 'home',
          menu: true,
        },
      ];

      loginRoutes.forEach((record) => {
        const route = {
          name: record.name,
          component: pages[record.component],
          path: record.path,
          // Automagically pass route params as props to component
          props: ({ params }) => {
            const props = {};
            // If id is defined, cast it to number
            if (params.id) {
              props.id = Number(params.id);
            }

            return props;
          },
          children: [],
          meta: {
            login: record.login === true,
            title: record.title,
            redirect: !!record.redirect,
            root: record.parent === undefined,
            menu: record.menu === true,
            icon: record.icon,
          },
        };

        if (record.redirect) {
          route.redirect = { name: record.redirect };
        }

        if (record.parent) {
          map.get(record.parent).children.push(route);
        }

        map.set(route.name, route);
      });

      routes.push(...[...map.values()].filter((r) => r.meta.root));
      commit(mutations.ROUTES_Add, routes);
    },
  },
};
