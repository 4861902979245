import en from './en.yml';
import pl from './pl.yml';
import lv from './lv.yml';
import ru from './ru.yml';

export default {
  en,
  pl,
  lv,
  ru,
};
