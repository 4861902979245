import Vue from 'vue';
import VueRouter from 'vue-router';

import Alert from '@pages/Alert';
import Alerts from '@pages/Alerts';
import AlertsReport from '@pages/AlertsReport';
import AlertRules from '@pages/AlertRules';
import AlertRule from '@pages/AlertRule';
import Dashboard from '@pages/Dashboard';
import Diagnostics from '@pages/Diagnostics';
import DeviceOperators from '@pages/DeviceOperators';
import DeviceOperatorGroups from '@pages/DeviceOperatorGroups';
import DeviceOperatorGroup from '@pages/DeviceOperatorGroup';
import DeviceOperatorsReport from '@pages/DeviceOperatorsReport';
import DeviceOperatorsDetailedReport from '@pages/DeviceOperatorsDetailedReport';
import DeviceOperator from '@pages/DeviceOperator';
import Devices from '@pages/Devices';
import Device from '@pages/Device';
import DeviceGroups from '@pages/DeviceGroups';
import DeviceGroup from '@pages/DeviceGroup';
import DevicesLocation from '@pages/DevicesLocation';
import EfficiencyReport from '@pages/EfficiencyReport';
import ShiftsReport from '@pages/ShiftsReport';
import Help from '@pages/Help';
import Manage from '@pages/Manage';
import Reports from '@pages/Reports';
import Sensors from '@pages/Sensors';
import Sensor from '@pages/Sensor';
import ProductionView from '@pages/ProductionView';
import DeviceEfficiencyReport from '@pages/DeviceEfficiencyReport';
import AdministrationDevices from '@pages/AdministrationDevices';
import AdministrationDevicesTypes from '@pages/AdministrationDevicesTypes';
import AdministrationSensors from '@pages/AdministrationSensors';
import AdministrationUsers from '@pages/AdministrationUsers';
import AdministrationUserGroups from '@pages/AdministrationUserGroups';
import Users from '@pages/Users';
import LoginForm from '@components/login/LoginForm';
import LogoutForm from '@components/login/LogoutForm';
import ResetForm from '@components/login/ResetForm';
import ResettingForm from '@components/login/ResettingForm';
import Terms from '@components/login/Terms';
import TermsEn from '@components/login/TermsEn';

// Use VueRouter
Vue.use(VueRouter);

const router = new VueRouter();

export const pages = {
  LoginForm,
  LogoutForm,
  ResetForm,
  ResettingForm,
  Terms,
  TermsEn,
  AdministrationDevices,
  AdministrationDevicesTypes,
  AdministrationSensors,
  AdministrationUsers,
  AdministrationUserGroups,
  Alert,
  Alerts,
  AlertsReport,
  AlertRules,
  AlertRule,
  Dashboard,
  Diagnostics,
  DeviceOperators,
  DeviceOperatorGroups,
  DeviceOperatorGroup,
  DeviceOperatorsReport,
  DeviceOperatorsDetailedReport,
  DeviceOperator,
  Devices,
  Device,
  DeviceGroups,
  DeviceGroup,
  DevicesLocation,
  EfficiencyReport,
  Help,
  Manage,
  Reports,
  Sensors,
  Sensor,
  ShiftsReport,
  ProductionView,
  DeviceEfficiencyReport,
  Users,
};
/**
 * Export VueRouter instance
 */

router.beforeEach((to, from, next) => {
  if (to.meta.login) {
    localStorage.removeItem('fm-oauth');
  }
  next();
});

export default router;
