// Import required vendor stylesheets (Vue Material, material icons, Roboto font faces)
import 'material-design-icons-woff';
import 'vue-tour/dist/vue-tour.css';
import 'roboto-fontface-woff/css/roboto/sass/roboto-fontface-light.scss';
import 'roboto-fontface-woff/css/roboto/sass/roboto-fontface-regular.scss';
import 'roboto-fontface-woff/css/roboto/sass/roboto-fontface-regular-italic.scss';
import 'roboto-fontface-woff/css/roboto/sass/roboto-fontface-medium.scss';
import 'roboto-fontface-woff/css/roboto/sass/roboto-fontface-bold.scss';
import 'vue-material/dist/vue-material.css';

// Import vendor javascript libraries
import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueTour from 'vue-tour';
import VueTrend from 'vuetrend';
import VueMaterial from 'vue-material';
import { sync } from 'vuex-router-sync';
import vuetify from './vuetify';

// Import stylesheets
import './styles/main.styl'; // relative stylesheets

// Import own javascript files
import globals from './globals';
import filters from './filters';
import plugins from './plugins';
import router from './router';
import store from './store';
import appFactory from './app';
import components from './components';

// Load config from global namespace
const config = CONFIG;

// Enable Vue libraries
Vue.use(VueMaterial);
Vue.use(PortalVue);
Vue.use(VueTour);
Vue.use(VueTrend);
Vue.use(filters, config);
Vue.use(plugins, config);

// Enable some global components
Vue.use(components, config);

// Register globals
globals.registerTo(window);

// Create application when page is loaded
window.onload = () => {
  // const options = CONFIG;
  // sync router and store by exposing dynamic "route" state in store
  sync(store, router, { moduleName: 'route' });

  // Google Analytics
  // if (window.ga && config.brand.ga) {
  //   const { ga } = window;
  //   ga('create', config.brand.ga, 'auto');
  //   ga('send', 'pageview', '/#/', '/login#/');
  //   router.afterEach((to) => ga('send', 'pageview', `/#${to.path}`));
  // }
  // Register themes for vue-material
  Vue.material.registerTheme(CONFIG.themes);

  // Force default theme to whole document
  document.body.classList.add('md-theme-default');

  // Create vue application instance by factory
  window.app = appFactory.create({
    config, router, store, vuetify,
  });
};
