// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/default/background.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/precimet/background.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/rejs/background.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login.default{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.login.precimet{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.login.rejs{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.login.rejs .login__card .login__card__header{background-color:#efefef}", "",{"version":3,"sources":["webpack://./src/components/login/Brand.styl"],"names":[],"mappings":"AACE,eACE,wDAAgE,CAClE,gBACE,wDAAiE,CACnE,YACE,wDAA6D,CAE3D,8CACE,wBAAkB","sourcesContent":[".login\n  &.default\n    background-image: url(../../assets/images/default/background.jpg)\n  &.precimet\n    background-image: url(../../assets/images/precimet/background.jpg)\n  &.rejs\n    background-image: url(../../assets/images/rejs/background.jpg)\n    .login__card\n      .login__card__header\n        background-color: #efefef\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
