<template>
  <div />
</template>

<script>
export default {
  data() {
    return {
    };
  },
  async created() {
    localStorage.removeItem('fm-oauth');

    window.location.reload(true);
  },
};
</script>
